import React from "react";
import {
  Typography,
  Grid,
  Card,
  Checkbox,
  Tooltip,
  IconButton,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { format } from "date-fns";
import CreateIcon from "@mui/icons-material/Create";
import { openDialog } from "../../../reusable/ScrollablePopup";
import ProjectDates from "./ProjectDates";
import ProjectOverviewModal from "../ProjectOverviewModal";
import { poundUK, requiredLabel } from "../../../queries/Project";
import { primaryColor } from "../../../assets/ThemeStyling";
import { practicalCompletionLabel } from "../../../assets/GlobalLabels";
import SmallFormPopover from "../../../reusable/SmallFormPopover";
import { projectStatuses } from "../../../queries/Project";
import { statusLabel } from "../../../assets/GlobalLabels";
import { Field, Form } from "react-final-form";
import ServicesStatus from "./ServicesStatus";

function ProjectPMDate({ date, yesno, label, hasfigure, figure }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
      }}
    >
      <Checkbox disabled={true} checked={yesno} />

      <div>{label}</div>
      <div
        style={{
          marginLeft: "auto",
        }}
      >
        {date ? format(new Date(date), "dd/MM/yyyy") : requiredLabel}
      </div>

      {hasfigure && (
        <div
          style={{
            marginLeft: "1em",
          }}
        >
          {poundUK.format(figure ? figure : 0)}
        </div>
      )}
    </div>
  );
}

export default function ProjectPM({ project, updateProject }) {
  return (
    <>
      <ServicesStatus
        project={project}
        updateProject={updateProject}
        property={"status_build"}
      />
      <Card
        style={{
          marginBottom: "24px",
          marginTop: "12px",
          padding: "0",
          width: "100%",
        }}
      >
        <Grid
          item
          xs
          style={{
            padding: "18px 24px",
            // borderBottom: "1px solid #E7EAEF",
            backgroundColor: "#4B4F54",
          }}
        >
          <Typography
            variant="h5"
            style={{
              fontWeight: "600",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              color: "white",
            }}
          >
            Project Overview
            <Tooltip title="Edit" placement="top">
              <IconButton
                onClick={() => {
                  openDialog({
                    content: (
                      <ProjectOverviewModal
                        project={project}
                        onSaveClicked={(newProject) => {
                          updateProject({
                            variables: {
                              ...newProject,
                              _id: project._id,
                            },
                          });
                        }}
                      />
                    ),
                  });
                }}
              >
                <CreateIcon style={{ fill: primaryColor }} />
              </IconButton>
            </Tooltip>
          </Typography>
        </Grid>

        <Grid
          container
          className="MuiTypography-body1"
          spacing={3}
          style={{ padding: "1em" }}
        >
          <Grid item xs={12} md={4}>
            <ProjectPMDate
              date={project.practical_completion_date}
              yesno={project.practical_completion}
              label={practicalCompletionLabel}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ProjectPMDate
              date={project.hs_returned_date}
              yesno={project.hs_returned}
              label="H&amp;S Returned"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ProjectPMDate
              date={project.final_accounts_date}
              yesno={project.final_accounts_yesno}
              label="Final Accounts"
              hasfigure={true}
              figure={project.final_accounts}
            />
          </Grid>
          <Grid item xs={false} md={4}>
            &nbsp;
          </Grid>
          <Grid item xs={12} md={4}>
            <ProjectPMDate
              date={project.om_manuals_date}
              yesno={project.om_manuals}
              label="O&amp;M Manuals"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ProjectPMDate
              date={project.retention_date}
              yesno={project.retention_yesno}
              label="Retention"
              hasfigure={true}
              figure={project.retention}
            />
          </Grid>
        </Grid>
      </Card>

      <ProjectDates
        project_id={project.project_id}
        department=""
        title="Project Dates"
      />
    </>
  );
}
